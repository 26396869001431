<template>
  <div>
    <CRow v-if="market.id">
      <CCol md="3">
        <CCard>
          <CCardBody class="text-center">
            <img
              v-if="market.role.image_rect_cropped"
              v-bind:src="market.role.image_rect_cropped"
              class="img-fluid"
              :alt="market.role.name"
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="12">
        <CCard>
          <CCardBody>
            <div v-if="items_loading" class="loading text-center">
              <CSpinner color="secondary" size="lg" />
            </div>
            <CRow v-else>
              <CCol md="2"> </CCol>
              <CCol md="6"> Prodotto </CCol>
              <CCol md="2"> Quanità </CCol>
              <CCol md="2"> Prezzo </CCol>
            </CRow>
            <MarketCartLine
              v-for="(item, key) in items"
              :key="key"
              v-model="items[key]"
              v-on:delete-item="deleteItem(key)"
            />
            <!-- <CRow v-for="(item, key) in items" :key="key">
              <CCol md="2">
                <img
                  v-if="item.ppm.lot.productproducer.image_cropped"
                  v-bind:src="item.ppm.lot.productproducer.image_cropped"
                  class="img-fluid"
                  :alt="item.ppm.lot.productproducer.translations.it.name"
                />
              </CCol>
              <CCol md="6">
                <h3>{{ item.ppm.lot.productproducer.translations.it.name }}</h3>
                <h5>{{ item.ppm.lot.productproducer.producer.role.name }}</h5>
                <p>{{ item.ppm.lot.translations.it.name }}</p>
              </CCol>
              <CCol md="2">
                <CButtonGroup>
                  <CInput horizontal v-model="item.qta">
                    <template #append-content>
                      <CIcon name="cil-loop-circular" />
                    </template>
                  </CInput>
                </CButtonGroup>
                <small>x {{ (item.ppm.price*1).toFixed(2) }} CHF</small>
              </CCol>
              <CCol md="2">
                <h2>{{ (item.ppm.price * item.qta).toFixed(2) }} CHF</h2>
              </CCol>

              <CCol md="12">
                <hr />
              </CCol>
            </CRow> -->
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow v-if="cart.id" class="justify-content-end">
      <CCol md="4">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol md="6"> <h3>Totale carrello</h3> </CCol>
              <CCol md="6">
                <h3>
                  <b>{{ cart.tot.toFixed(2) }} CHF</b>
                </h3>
              </CCol>
              <CCol md="12">
                <button class="btn btn-success btn-block btn-lg">
                  <b>Procedi con l'ordine</b>
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { GetDataManager, ApiCall } from "../../ds/index";
import { Query } from "@syncfusion/ej2-data";
import MarketCartLine from "./MarketCartLine";
export default {
  data() {
    return {
      items_loading: false,
      items: [],
    };
  },
  computed: {
    cart() {
      return this.$store.state.shop.market_cart;
    },
    market() {
      return this.$store.state.shop.market;
    },
  },
  watch: {
    "cart.id": function () {
      this.LoadCartDetails();
    },
  },
  methods: {
    deleteItem: function (index) {
      this.items.splice(index, 1);
    },
    LoadCartDetails() {
      let self = this;
      if (self.cart.id) {
        self.items_loading = true;
        self.items = [];
        ApiCall(
          // GetDataManager("role_producer_products", [this.$store.state.role.id]),
          GetDataManager("cart_usercarts_detail", [self.cart.id]),
          new Query().sortBy(
            "ppm.lot.productproducer.producer.role.name",
            "ascending"
          ),
          function (e) {
            // self.numPages = Math.ceil(e.actual.Count / 12);
            // self.$store.cart.set(e.result);
            // self.$store.dispatch("cart/add", e.result);
            // self.$store.commit("shop/setCart", e.result)
            // console.log(e.result)
            self.items_loading = false;
            self.items = e.result;
          }
        );
      }
    },
  },
  mounted() {
    this.LoadCartDetails();
  },
  //   name: "ProfileData",
  components: {
    MarketCartLine,
  },
};
</script>
